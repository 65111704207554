<script setup type="ts">
  import NavBar from "./NavBarView.vue";
</script>

<template>
  <NavBar />
  <div id="spinner">
    <img src="/icons/spinner.svg" alt="Loading" />
  </div>
  <div id="tableouter">
    <table>
      <tbody>
        <tr>
          <th>
            Name
          </th>
          <th>
            Actions
          </th>
        </tr>
        <tr v-for="category in categories" v-bind:key="category.id">
          <td>{{ category.name }}</td>
          <td>
            <img v-if="!category.global" @click="deleteCategory($event, category.id)" class="clickable center icon" src="/icons/delete.svg" alt="Delete">
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script type="ts">
  import {listCategories} from "../services/categories";

  export default {
    data() {
      return {
        categories: []
      }
    },
    methods: {
      deleteCategory(event, categoryId) {
        if (event) {
          alert("Delete category " + categoryId);
        }
      }
    },
    mounted() {
      listCategories().then((response) => {
        this.categories = response;
        
        document.getElementById("spinner").style.display = "none";
        document.getElementById("tableouter").style.display = "block";
      })
    }
  }
</script>

<style>
#spinner {
  width: 100%;
  height: 100px;
}
#spinner img {
  display:block;
  margin-left:auto;
  margin-right:auto;
  width: 100px;
}
#tableouter {
  min-width: 100%;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  display: none;
}
table {
  border-collapse: collapse;
  width: 100%;
}
table th {
  border: solid 1px #cad4de;
  padding: 5px;
}
table td {
  border: solid 1px #cad4de;
  padding: 5px;
}
.positive {
  color: #49ba5d;
}
.negative {
  color: #fa1814;
}
.bold {
  font-weight: 900;
}
.current {
  display: block;
  width: 100%;
}
.clickable {
  cursor: pointer;
}
.right {
  float: right;
}
.icon {
  width: 1em;
  height: 1em;
}
.center {
  display:block;
  margin-left: auto;
  margin-right: auto;
}
.selector {
  display: none;
  width: 100%;
}
.selector select {
  width: 100%;
}
.selector button {
  width: 100%;
}
</style>
