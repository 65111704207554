import { createRouter, createWebHistory } from "vue-router";
import AnalyticsView from "@/components/AnalyticsView.vue";
import CategoriesView from "@/components/CategoriesView.vue";
import RegistrationView from "@/components/RegistrationView.vue";
import ReportView from "@/components/ReportView.vue";
import TransactionsView from "@/components/TransactionsView.vue";
import LoginStep1View from "@/components/LoginStep1View.vue";
import LoginStep2View from "@/components/LoginStep2View.vue";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    { path: "/", name: "analytics", component: AnalyticsView },
    { path: "/registration/:attemptUuid", name: "registration", component: RegistrationView, props: (route) => ({ attemptUuid: route.params.attemptUuid })},
    { path: "/report", name: "report", component: ReportView },
    { path: "/transactions", name: "transactions", component: TransactionsView },
    { path: "/login-step-1", name: "login-step-1", component: LoginStep1View },
    { path: "/login-step-2/:attemptUuid", name: "login-step-2", component: LoginStep2View, props: (route) => ({ attemptUuid: route.params.attemptUuid })},
    { path: "/categories", name: "categories", component: CategoriesView },
  ]
});

export default router;
