<template>
  <div className="login">
      <button @click="submit">Confirm registration</button>
  </div>
</template>

<script type="ts">
  import router from "@/router"
  import {register} from "../services/login";

  export default {
    props: ["attemptUuid"],
    methods: {
      submit() {
        register(this.attemptUuid).then(() => {
          router.push({name: "analytics"});
        });
      }
    }
  }
</script>

<style>
  .login {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  button {
    display: block;
    margin: auto;
  }
</style>
