import axios from "axios"
import moment from "moment"
import {Types} from "../types/types"

export async function listTransactions(fromMoment: moment.Moment, toMoment: moment.Moment): Promise<Types.Transaction[]> {
  const { data } = await axios.get("/api/v1/transactions", {params:{fromTm: fromMoment.unix(), toTm: toMoment.unix()}});

  return data;
}

export async function setTransactionCategory(transactionId: string, categoryId: number): Promise<Types.Transaction> {
  const transactionIdEncoded = encodeURIComponent(transactionId);
  const { data } = await axios.put(`/api/v1/transactions/${transactionIdEncoded}/category`, {
    categoryId: categoryId,
    scope: "TRANSACTION"
  });

  return data;
}

export async function setMerchantCategory(transactionId: string, categoryId: number): Promise<Types.Transaction> {
  const transactionIdEncoded = encodeURIComponent(transactionId);
  const { data } = await axios.put(`/api/v1/transactions/${transactionIdEncoded}/category`, {
    categoryId: categoryId,
    scope: "MERCHANT"
  });

  return data;
}

export async function setTransactionHidden(transactionId: string, hidden: boolean): Promise<Types.Transaction> {
  const transactionIdEncoded = encodeURIComponent(transactionId);
  const { data } = await axios.put(`/api/v1/transactions/${transactionIdEncoded}/hidden`, {
    hidden: hidden
  });

  return data;
}
