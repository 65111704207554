<template>
  <div class="app_menu">
    <RouterLink to="/">Analytics</RouterLink>
    <RouterLink to="/report">Report</RouterLink>
    <RouterLink to="/transactions">Transactions</RouterLink>
    <RouterLink to="/categories">Categories</RouterLink>
    <img @click="logout" class="logout" src="/icons/logout.svg" />
  </div>
</template>

<script type="ts">
  import router from "@/router"
  import {logout} from "../services/login";

   export default {
    methods: {
      logout(event) {
        if (event) {
          logout();
          router.push({name: "login-step-1"});
        }
      }
    }
  }
</script>

<style>
  .app_menu {
    display: block;
    width: 100%;
    text-align: right;
  }
  .app_menu a {
    margin-right: 10px;
    font-size: 1.5em;
    text-decoration: none;
    color: inherit;
  }
  .logout {
    height: 100%;
    cursor: pointer;
  }
</style>