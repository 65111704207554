import axios from "axios"
import {Types} from "../types/types"

export async function loginStep1(email: string): Promise<Types.Confirmation> {
  const { data } = await axios.post("/api/v1/login/step1", {
    email: email
  })

  return data;
}

export async function loginStep2(attemptUuid: string, confirmationCode: string): Promise<Types.LoginStep2Response> {
  const { data } = await axios.post("/api/v1/login/step2", {
    attemptUuid: attemptUuid,
    confirmationCode: confirmationCode
  });
  
  return data;
}

export async function register(attemptUuid: string): Promise<void> {
  const { data } = await axios.post("/api/v1/login/register", {
    attemptUuid: attemptUuid
  });
  
  return data;
}

export async function logout(): Promise<void> {
  const { data } = await axios.get("/api/v1/login/logout");
  
  return data;
}