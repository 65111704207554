<template>
  <div className="login">
      <label>
        Email:
        <input type="email" v-model="email" />
      </label>
      <br />
      <button @click="submit">Log in</button>
  </div>
</template>

<script type="ts">
  import router from "@/router"
  import {loginStep1} from "../services/login";

  export default {
    data() {
      return {
        email: ""
      }
    },
    methods: {
      submit() {
        loginStep1(this.email).then((response) => {
          router.push({name: "login-step-2", params: { attemptUuid: response.attemptUuid }});
        });
      }
    }
  }
</script>

<style>
  .login {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  input {
    margin: 5px;
  }

  button {
    display: block;
    margin: auto;
  }
</style>
