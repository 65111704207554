import axios from "axios"
import moment from "moment"
import {Types} from "../types/types"

export async function getReport(fromMoment: moment.Moment, toMoment: moment.Moment): Promise<Types.Report> {
  const fromTm = fromMoment.unix();
  const toTm = toMoment.unix();
  const { data } = await axios.get(`/api/v1/report?fromTm=${fromTm}&toTm=${toTm}`);

  return data;
}