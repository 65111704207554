<script setup type="ts">
  import NavBar from "./NavBarView.vue";
  import VueDatePicker from "@vuepic/vue-datepicker";
  import "@vuepic/vue-datepicker/dist/main.css";
</script>

<template>
  <NavBar />
  <div class="filtersouter">
    <VueDatePicker month-picker range v-model="dates" @update:model-value="onDatesUpdate" lang="en" />
  </div>
  <div id="spinner">
    <img src="/icons/spinner.svg" alt="Loading" />
  </div>
  <div id="tableouter">
    <table>
      <tbody>
        <tr>
          <th></th>
          <th v-for="date in report.dates" v-bind:key="date">
            {{ date }}
          </th>
        </tr>
        <tr v-for="categoryStat in report.categoriesStat" v-bind:key="categoryStat">
          <td class="bold">
            {{ categoryStat.category.name }}
          </td>
          <td v-for="dateStat in categoryStat.datesStat" v-bind:key="dateStat">
            <span v-if="dateStat.spentAmount > 0" class="positive bold">+</span>  
            <span v-else-if="dateStat.spentAmount < 0" class="negative bold">-</span>{{ Math.floor(Math.abs(dateStat.spentAmount) * 100) / 100 }}
            <img v-if="dateStat.missingRates.length > 0" class="clickable right icon" src="/icons/attention.svg" alt="Attention">
          </td>
        </tr>
        <tr>
          <td class="bold">Total</td>
          <td v-for="totalSpentAmount in report.totalSpentAmounts" v-bind:key="totalSpentAmount">
            <span v-if="totalSpentAmount > 0" class="positive bold">+</span>  
            <span v-else-if="totalSpentAmount < 0" class="negative bold">-</span>{{ Math.floor(Math.abs(totalSpentAmount) * 100) / 100 }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script type="ts">
  import moment from "moment"
  import {getReport} from "../services/report";

  export default {
    data() {
      return {
        report: {},
        dates: []
      }
    },
    methods: {
      onDatesUpdate(dates) {
        document.getElementById("tableouter").style.display = "none";
        document.getElementById("spinner").style.display = "block";

        const fromMoment = moment(dates[0]).startOf("month").startOf("day");
        const toMoment = moment(dates[1]).endOf("month").endOf("day");

        getReport(fromMoment, toMoment).then((response) => {
          this.report = response;
          document.getElementById("spinner").style.display = "none";
          document.getElementById("tableouter").style.display = "block";
        })
      }
    },
    mounted() {
      const fromMoment = moment(new Date()).subtract(11, "months").startOf("month").startOf("day");
      const toMoment = moment(new Date()).endOf("month").endOf("day");

      this.dates = [
        {
          year: fromMoment.year(),
          month: fromMoment.month()
        },
        {
          year: toMoment.year(),
          month: toMoment.month()
        }
      ];
      this.onDatesUpdate(this.dates);
    }
  }
</script>

<style>
#spinner {
  width: 100%;
  height: 100px;
}
#spinner img {
  display:block;
  margin-left:auto;
  margin-right:auto;
  width: 100px;
}
.filtersouter {
  width: 250px;
}
#tableouter {
  min-width: 100%;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  display: none;
}
table {
  border-collapse: collapse;
  min-width: 100%;
}
table th {
  border: solid 1px #cad4de;
  padding: 5px;
}
table td {
  border: solid 1px #cad4de;
  padding: 5px;
}
.positive {
  color: #49ba5d;
}
.negative {
  color: #fa1814;
}
.bold {
  font-weight: 900;
}
.clickable {
  cursor: pointer;
}
.right {
  float: right;
}
.icon {
  width: 1em;
  height: 1em;
}
</style>
