<template>
  <div className="login">
      <label>
        Confirmation code:
        <input type="text" v-model="confirmationCode" />
      </label>
      <br />
      <button @click="submit">Confirm</button>
  </div>
</template>

<script type="ts">
  import router from "@/router"
  import {loginStep2} from "../services/login";

  export default {
    props: ["attemptUuid"],
    data() {
      return {
        confirmationCode: ""
      }
    },
    methods: {
      submit() {
        loginStep2(this.attemptUuid, this.confirmationCode).then((response) => {
          console.log(response);
          if (response.registrationRequired === true) {
            router.push({name: "registration", params: { attemptUuid: response.attemptUuid }});
          } else {
            router.push({name: "analytics"});
          }
        });
      }
    }
  }
</script>

<style>
  .login {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  input {
    margin: 5px;
  }

  button {
    display: block;
    margin: auto;
  }
</style>
